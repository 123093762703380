.neos-search {
  &.ajax {
    .search-results {
      margin-top: 15px;

      .fa.fa-spinner {
        font-size: 22px;
      }

      li {
        margin-bottom: 15px;
      }
    }
  }

  &.results {
    .search-results {
      margin-top: 15px;

      a + div {
        margin-bottom: 15px;
      }
    }
  }
}